import { modelT } from "../../redux/features/ui/ui.types";

export const modelsSectionalPresets: modelT[] = [
  {
    img: "/images/assets/CollectionImages/AdditionalItems/Best-Seller-1.png",
    imageLabel: "Best-Seller-1",
    label: "Bestseller #1",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "20643e44-fecf-437f-b01d-c8fc3ed37322",
        "pos_positive_1#": "381ee70b-92ab-4ef1-baa7-6c479f2f223c",
        "pos_negative_1#": "9d3154dd-2278-4d62-a0b5-c498850007b6",
        "pos_negative_2#": "d5d53809-a702-4bc3-954b-dacf7be5fdb0",
        "pos_negative_3#": "3966831e-8986-40bc-8396-fa4057d98b63",
      },
      activeParamsThreekit: {
        Materials: "Malibu_Dove",
        "Arm selector": "Track",
        ArmSelectorWidth: "classic",
        "Legs selector": "Tapered leg",
        "Seat depth": "Classic",
        "Legs material": "Carbon",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/CollectionImages/AdditionalItems/Best-Seller-2.png",
    imageLabel: "Best-Seller-2",
    label: "Bestseller #2",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "a029efa0-4043-48bf-9eb4-2a6535af60d9",
        "pos_positive_1#": "2811ace4-6ff3-4d7a-9513-7e2167f7329b",
      },
      activeParamsThreekit: {
        Materials: "Malibu_Dove",
        "Arm selector": "Flare",
        "Legs selector": "Cone leg",
        "Legs material": "Carbon",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/CollectionImages/AdditionalItems/Best-Seller-3.png",
    imageLabel: "Best-Seller-3",
    label: "Bestseller #3",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "a029efa0-4043-48bf-9eb4-2a6535af60d9",
        "pos_positive_1#": "d9212423-63e3-4ef5-abd8-7cc1f9157b19",
        "pos_positive_2#": "273be62a-46a4-42a5-b5c3-c27382520c22",
      },
      activeParamsThreekit: {
        Materials: "Malibu_Dove",
        "Arm selector": "Roll",
        "Legs selector": "Cone leg",
        "Legs material": "Natural",
      },
    },
    subcategory: "Sectionals",
  },
  // {
  //   img: '/images/assets/CollectionImages/AdditionalItems/Best-Seller-4.png',
  //   imageLabel: "Best-Seller-4",
  //   label: "Bestseller #4",
  //   nameValueThreekit: {
  //     models: {
  //       "pos_first_0#": "a029efa0-4043-48bf-9eb4-2a6535af60d9",
  //       "pos_positive_1#": "d9212423-63e3-4ef5-abd8-7cc1f9157b19",
  //       "pos_positive_2#": "273be62a-46a4-42a5-b5c3-c27382520c22",
  //     },
  //     activeParamsThreekit: {
  //       Materials: "Malibu_Dove",
  //       "Arm selector": "Roll",
  //       "Legs selector": "Cone leg",
  //       "Legs material": "Natural",
  //     },
  //   },
  // },
  // {
  //   img: '/images/assets/CollectionImages/AdditionalItems/Best-Seller-5.png',
  //   imageLabel: "Best-Seller-5",
  //   label: "Bestseller #5",
  //   nameValueThreekit: {
  //     models: {
  //       "pos_first_0#": "a029efa0-4043-48bf-9eb4-2a6535af60d9",
  //       "pos_positive_1#": "d9212423-63e3-4ef5-abd8-7cc1f9157b19",
  //       "pos_positive_2#": "273be62a-46a4-42a5-b5c3-c27382520c22",
  //     },
  //     activeParamsThreekit: {
  //       Materials: "Malibu_Dove",
  //       "Arm selector": "Roll",
  //       "Legs selector": "Cone leg",
  //       "Legs material": "Natural",
  //     },
  //   },
  // },
];
