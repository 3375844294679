import { useState } from "react";
import useMobileWidth from "../../hooks/useMobileWidth";
import { changeThreekitConfiguration } from "../../redux/actions/player.action";
import { dataForChangeAttributeThreekit } from "../../redux/features/ui/ui.types";
import { getActiveParamFromIdComponentUI } from "../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { StandartOption } from "../StandartOption/StandartOption";
import s from "./StandartOptionsWrapSlider.module.scss";

const settingsSlider = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const settingsSliderBig = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const StandartOptionsWrapSlider = ({ ...props }) => {
  const { id, values, label, presetModels, isShowHideOptions, itemsToShow, additionalProperties, link } = props;
  const [showMore, setShowMore] = useState(false);
  const isMobileSize = useMobileWidth();
  const dispatch = useAppDispatch();
  const valueActiveOption = useAppSelector(getActiveParamFromIdComponentUI(id));

  const changeOptional = ({ name, value }: dataForChangeAttributeThreekit) => {
    dispatch(changeThreekitConfiguration({ [id]: name }));
  };

  const onShowHideMoreOptions = () => {
    setShowMore(!showMore);
  };

  // const itemsToShow =

  const isPresetsOptions = !!presetModels && presetModels;

  const settingsForSlider = isPresetsOptions ? settingsSliderBig : settingsSlider;

  const MIN_LENGTH_OPTIONS = isMobileSize ? itemsToShow : 5;

  const isBtnShowHide = isShowHideOptions && values.length > MIN_LENGTH_OPTIONS;
  const loadCount = showMore ? values.length : MIN_LENGTH_OPTIONS;

  return (
    <div className={s.optionsWrap}>
      {/* {!!label && <div className={s.optionsWrapLabel}>{label}</div>} */}
      {!isMobileSize ? (
        <div
          className={`${s.optionsWrapDesktop} ${
            isPresetsOptions ? s.optionsWrapDesktop_presets_complete : s.optionsWrapDesktop_presets
          } ${isSectionalConfigurator() && s.optionsWrapDesktop_presets_sectional}`}
        >
          {values.slice(0, isShowHideOptions ? loadCount : values.length).map((option: any) => (
            <StandartOption
              id={!!option.value ? option.value : option.label}
              key={!!option.value ? option.value : option.label}
              parentId={id}
              activeValue={valueActiveOption}
              subcategory={option.subcategory}
              {...option}
              changeOptional={(info: any) => changeOptional(info)}
              link
            />
          ))}
        </div>
      ) : (
        <div
          className={`${s.optionsWrapMobile} ${
            isPresetsOptions ? s.optionsWrapDesktop_presets_complete : s.optionsWrapDesktop_presets
          }`}
        >
          {values.slice(0, isShowHideOptions ? loadCount : values.length).map((option: any) => (
            <StandartOption
              id={!!option.value ? option.value : option.label}
              key={!!option.value ? option.value : option.label}
              parentId={id}
              activeValue={valueActiveOption}
              subcategory={option.subcategory}
              {...option}
              changeOptional={(info: any) => changeOptional(info)}
              link
            />
          ))}
        </div>
      )}
      {isBtnShowHide && (
        <div className={s.showMoreWrap}>
          <span className={`${showMore ? s.open : ""}`} onClick={() => onShowHideMoreOptions()}>
            {showMore ? "Hide" : "Show"} more pieces <img src="/images/assets/svg/ArrowShowMore.svg" alt="" />
          </span>
        </div>
      )}
    </div>
  );
};
