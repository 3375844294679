import { modelT } from "../../redux/features/ui/ui.types";

export const modelsSectionalSections: modelT[] = [
  {
    img: "/images/assets/Sectional/LAFChair.png",
    label: "Left Arm Facing Chair",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "3966831e-8986-40bc-8396-fa4057d98b63",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Left Arm Facing Cuddle Chair",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "49cc1d9c-526d-41e9-a3d9-35c30a1052e2",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Left Arm Facing Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "76369a5d-6117-4a08-b9b4-56fb78c2dee4",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Left Arm Facing Cuddle Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "588871b2-425e-4b64-ac0f-18cce1baf65b",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Left Arm Facing Bumper Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "94a25b87-f723-4988-8dc2-c9b70bd2e4a9",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Corner Unit",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "d9212423-63e3-4ef5-abd8-7cc1f9157b19",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Cuddle Corner",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "9d3154dd-2278-4d62-a0b5-c498850007b6",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Left Arm Facing Loft Sofa",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "a029efa0-4043-48bf-9eb4-2a6535af60d9",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/ArmlessChair.png",
    label: "Armless Chair",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "d5d53809-a702-4bc3-954b-dacf7be5fdb0",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Armless Loft Sofa",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "20643e44-fecf-437f-b01d-c8fc3ed37322",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Chair",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "a66e83b9-82bf-4074-a3dc-fda07937a02a",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Cuddle Chair",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "19c056be-8a28-4963-991c-3205fee0ff1e",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "381ee70b-92ab-4ef1-baa7-6c479f2f223c",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Cuddle Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "2811ace4-6ff3-4d7a-9513-7e2167f7329b",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Bumper Chaise",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "273be62a-46a4-42a5-b5c3-c27382520c22",
      },
    },
    subcategory: "Sectionals",
  },
  {
    img: "/images/assets/Sectional/RAFChair.png",
    label: "Right Arm Facing Loft Sofa",
    nameValueThreekit: {
      models: {
        "pos_first_0#": "cfcb1e2a-222a-43f3-9f58-877ffa8db8ed",
      },
    },
    subcategory: "Sectionals",
  },
];
