import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackArrow } from "../../assets/svgCompopnent/BackArrow";
import StandartModelsListGroup from "../../components/StandartModelsListGroup/StandartModelsListGroup";
import { addActiveModel, addActiveThreeekitPillow, updateModelsPrice } from "../../redux/actions/player.action";
import { collectionsKeysT, modelsGroupT } from "../../redux/features/ui/ui.types";
import {
  changeLoadInit3kitState,
  setActiveColection,
  setActivePillow,
  setAllThreekitAtributes,
  setInitialState,
} from "../../redux/features/ui/uiSlice";
import {
  getActiveCollectionModelsGroupsList,
  getCollectionCategoryesByTab,
  getCollectionTabs,
  getEffectiveAccountId,
  getIsInStore,
  getUserType,
} from "../../redux/selectors/ui.selector";
import { PAGES } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { getDisplayAttributes } from "../../utils/threekit/attributesFunctions";
import s from "./PresetPage.module.scss";

export default function PresetPage() {
  let { collection } = useParams();
  const navigate = useNavigate();

  const effectiveAccoundId = useAppSelector(getEffectiveAccountId);
  const inStore = useAppSelector(getIsInStore);
  const userType = useAppSelector(getUserType);

  const dispatch = useAppDispatch();

  const modelsActiveCollection = useAppSelector((state) => getActiveCollectionModelsGroupsList(state));
  const objTabs = useAppSelector((state) => getCollectionTabs(state));
  const objCategoryes = useAppSelector((state) => getCollectionCategoryesByTab(state)(objTabs[0]));

  React.useEffect(() => {
    const storeJSON = {};
    // setStoreInLocalStorage(storeJSON);
    if (!!collection) {
      if (!isSectionalConfigurator()) dispatch(changeLoadInit3kitState(true));
      dispatch(setActiveColection(collection as collectionsKeysT));

      if (objCategoryes && objTabs.length >= 1) {
        dispatch(setActivePillow(""));
        dispatch(addActiveThreeekitPillow({ id: "" }));
        window.sessionStorage.setItem("store", "{}");
        window.sessionStorage.setItem("itemType", "");

        // dispatch(setActiveTab(objTabs[0]));
        // dispatch(setActiveCategory(objCategoryes[0]));
      }
    }

    if (window.playerT) {
      window.player.camera.frameBoundingSphere();
      const attr = window.playerT.configurator.getDisplayAttributes();
      const length = Object.keys(attr).length;
      Object.keys(attr).forEach((key, index) => {
        const name = attr[key].name;
        const defaultValue = attr[key].defaultValue;
        window.configurator
          .setConfiguration({
            [name]: defaultValue,
          })
          .then((e: any) => {
            if (index + 1 === length) {
              dispatch(
                setAllThreekitAtributes({
                  arrAllThreekitAtributes: getDisplayAttributes(),
                  isNewObjectActiveParams: true,
                })
              );

              dispatch(updateModelsPrice());
            }
          });
      });
    }
    // dispatch(setActiveParamsUI({ [paramsUI]: 'Monaco_Steel' }));
    if (window.playerT) {
      // dispatch(setAllThreekitAtributes(getDisplayAttributes()));
    }

    if (modelsActiveCollection.length === 0) navigate(PAGES.config, { state: { collectionId: collection } });
  }, []);

  const handleClickBack = () => {
    window.location.href = `${PAGES.index}?effectiveAccountId=${effectiveAccoundId}&inStore=${inStore}&userType=${userType}`;

    dispatch(setInitialState());
    dispatch(addActiveModel({ id: "" }));
    // navigate(PAGES.index);
  };

  return (
    <div className={s.mainPreset}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.logo} onClick={() => handleClickBack()}>
            <img src="/images/assets/MainLogo.png" alt="" />
          </div>
          <div onClick={() => handleClickBack()} className={s.back}>
            <BackArrow />
            <span>Change furniture</span>
          </div>
        </div>
        <div className={s.content}>
          {modelsActiveCollection.map((modelsGroup: modelsGroupT, index: number) => (
            <StandartModelsListGroup key={`${modelsGroup.title}_${index}`} {...modelsGroup} />
          ))}
        </div>
      </div>
    </div>
  );
}
