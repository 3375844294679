import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import useMobileWidth from "../../../hooks/useMobileWidth";
import { collectionUIT } from "../../../redux/features/ui/ui.types";
import { setActiveColection } from "../../../redux/features/ui/uiSlice";
import { getMenuMainCollectionsArr, getRestrictionByCategory } from "../../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { filterArrayByRestrictions } from "../../../utils/restriction/restrictionUtils";
import s from "./MenuMain.module.scss";

export const funcGetUrlIcon = (url: string | any, classImg?: string) => {
  if (url === undefined) return <></>;
  let urlDone = url.default ? url.default : url;

  if (urlDone.includes("./static/")) urlDone = urlDone.replace("./static/", "/static/");
  return <img src={urlDone} className={classImg} alt="" />;
};

let settingsSlider = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function MenuMain() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileSize = useMobileWidth();
  const collections = useAppSelector((state) => getMenuMainCollectionsArr(state));
  const categoryRestriction = useAppSelector(getRestrictionByCategory);

  const handleClick = (collection: collectionUIT) => {
    dispatch(setActiveColection(collection.id));
    navigate(collection.id, { state: { collectionId: collection.id } });
  };

  const filteredCollections = categoryRestriction
    ? filterArrayByRestrictions(collections, categoryRestriction)
    : collections;

  return (
    <>
      {!isMobileSize ? (
        <div className={s.catalog_main}>
          {filteredCollections.map((collection: collectionUIT, index: number) => (
            <div className={s.catalog_item} key={`${collection.id}_${index}`} onClick={() => handleClick(collection)}>
              {/* <div alt={collection.label} className={s.slideImg}>{funcGetUrlIcon(collection.img)} </div> */}
              <img src={collection.img} alt={collection.label} />
              {/* {funcGetUrlIcon(collection.img)} */}
              <span className={s.catalog_item_title}>{collection.label}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className={s.catalog_main_mob}>
          <Slider {...settingsSlider}>
            {filteredCollections.map((collection: collectionUIT, index: number) => (
              <div className={s.catalog_item} key={`${collection.id}_${index}`} onClick={() => handleClick(collection)}>
                <img src={collection.img} alt={collection.label} />
                {/* {funcGetUrlIcon(collection.img)} */}

                <span className={s.catalog_item_title}>{collection.label}</span>
              </div>
              // <Link to={PAGES.preset} key={`${collection.id}_${index}`} className={s.catalog_item}>
              //     <img src={collection.img} alt={collection.name} />
              //     <span className={s.catalog_item_title}>{collection.name}</span>
              // </Link>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}
