import { configureStore } from "@reduxjs/toolkit";
import { isSectionalConfigurator } from "./../utils/supportUtils";
import threekitReducer from "./features/threekit/threekit.reducer";
import { ThreekitState } from "./features/threekit/threekit.types";
import { UIState } from "./features/ui/ui.types";
import uiReducer from "./features/ui/uiSlice";
import { threekitMiddleware } from "./middlewares/player.middleware";

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    threeKit: threekitReducer,
  },
  middleware: [threekitMiddleware],
});

export function setStoreInLocalStorage(storeJSON: any) {
  window.sessionStorage.setItem("store", JSON.stringify(storeJSON));
}

store.subscribe(() => {
  setTimeout(() => {
    if (!isSectionalConfigurator()) {
      const config = window.configurator?.getConfiguration();
      const storeJSON = {
        //@ts-ignore
        variant: config,
        metadata: store.getState(),
      };
      setStoreInLocalStorage(storeJSON);
    }
  }, 1000);
});

export type Store = typeof store;

export type RootState = ReturnType<typeof store.getState>;
export type RootStateUI = {
  ui: UIState;
  threeKit?: ThreekitState;
};
export type AppDispatch = typeof store.dispatch;
