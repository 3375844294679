import { useEffect } from "react";
import { Point } from "../../assets/svgCompopnent/Point";

import { changeThreekitConfiguration, setModel, updateModelsPrice } from "../../redux/actions/player.action";
import { changeLoadPlayerState, setActiveParamsUI } from "../../redux/features/ui/uiSlice";
import { getActiveParamFromIdComponentUI, getLoadPlayer } from "../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";

import { StandartOption } from "../StandartOption/StandartOption";
import { ComponentHints } from "./ComponentHints";
import { getActualValue } from "./getActualValue";
import s from "./StandartOptionsWrap.module.scss";

export const StandartOptionsWrap = ({ ...props }) => {
  const { id, label, typeOptions, values, value, note, additionalProperties } = props;

  const dispatch = useAppDispatch();

  const isLoadPlayer = useAppSelector(getLoadPlayer);

  // get activeValue from Threekit or UI config
  const valueActiveOption = useAppSelector(getActiveParamFromIdComponentUI(id));
  const activeValue = !!valueActiveOption ? valueActiveOption : value;

  // set activeParameter in UI if NOT threekit value
  useEffect(() => {
    if (!!!valueActiveOption) {
      dispatch(setActiveParamsUI({ [id]: activeValue }));
      setTimeout(() => {
        dispatch(updateModelsPrice());
      }, 0);
    }
  }, []);

  const changeOptional = ({ name, value }: { name: string; value: string }) => {
    if (id === "AdditionalItems") {
      addNewModel(value);
    } else {
      dispatch(changeThreekitConfiguration({ [id]: name }));
    }
  };

  let valuesOptions = getActualValue(id, values);

  function addNewModel(value: any) {
    dispatch(changeLoadPlayerState(true));
    dispatch(setModel({ assetId: value }));
  }

  const checkIsSelectModelAndNotEmpty = id === "AdditionalItems" && valuesOptions.length === 0;

  const loadingItemsAfterClick =
    (id === "pillows" && isLoadPlayer) || (id === "AdditionalItems" && isSectionalConfigurator() && isLoadPlayer);

  return (
    <>
      <div className={s.optionsWrap}>
        <ComponentHints label={label} note={note} id={id} />
        <div className={s.optionsWrapDesktop}>
          {valuesOptions.map((option: any, index: number) => {
            return (
              <StandartOption
                id={`${option.name.trim()}_${index}`}
                key={`${option.name.trim()}_${index}`}
                parentId={id}
                activeValue={activeValue}
                item={option}
                disableAllButtons={loadingItemsAfterClick}
                {...option}
                buttonText={
                  !!additionalProperties && !!additionalProperties["buttonText"]
                    ? additionalProperties["buttonText"]
                    : "select"
                }
                changeOptional={(info: any) => changeOptional(info)}
              />
            );
          })}
        </div>
        {checkIsSelectModelAndNotEmpty && (
          <div className={s.hint}>
            Click on <Point /> and select a component from this panel to add another piece to your configuration.
          </div>
        )}
      </div>
    </>
  );
};
