import { FC } from "react";
import { Close } from "../../../../../assets/svgCompopnent/Close";
import ModalsWrapper from "../../../ModalsWrapper/ModalsWrapper";
import s from "./CartRequestModal.module.scss";
import { CartRequestModalPropsT } from "./types";

const CartRequestModal: FC<CartRequestModalPropsT> = ({ isOpen, onClose, isLoading, errorMessage }) => {
  if (!isOpen) return null;
  return (
    <ModalsWrapper>
      <div className={s.wrap}>
        <div className={s.body}>
          <div className={s.close} onClick={onClose}>
            <Close />
          </div>
          {isLoading && <div className={s.loader} />}
          {!isLoading && errorMessage && (
            <div className={s.errorBox}>
              <h2>Something went wrong:</h2>
              <p>{errorMessage}</p>
            </div>
          )}
          {!isLoading && !errorMessage && (
            <div className={s.message}>Successfully sent. This dialog will automatically close in few seconds.</div>
          )}
        </div>
      </div>
    </ModalsWrapper>
  );
};

export default CartRequestModal;
