import { modelT } from "../../redux/features/ui/ui.types";

export const modelsBedroom: modelT[] = [
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Twin",
    nameValueThreekit: "Twin",
    subcategory: "Rails",
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Full",
    nameValueThreekit: "Full",
    subcategory: "Rails",
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Queen",
    nameValueThreekit: "Queen",
    subcategory: "Rails",
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "California King",
    nameValueThreekit: "California King",
    subcategory: "Rails",
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Eastern King",
    nameValueThreekit: "Eastern King",
    subcategory: "Rails",
  },
];
