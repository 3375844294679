import { getSavedConfiguration } from "../../api/api";
import { setEffectiveAccountId } from "../../redux/features/ui/uiSlice";
import { getEffectiveAccountId } from "../../redux/selectors/ui.selector";
import { THREEKIT_PARAMS } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

export const redirectToSavedConfigurator = async () => {
  // const navigate = useNavigate();
  const effectiveAccountIdStore = useAppSelector(getEffectiveAccountId);

  const pathname = window.location.pathname;
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  let shortId = searchParams.get("shortId");
  const effectiveAccountId = searchParams.get("effectiveAccountId") || "";
  const inStore = searchParams.get("inStore") === "true" || false;
  const dispatch = useAppDispatch();

  if (effectiveAccountId && effectiveAccountIdStore === "DEFAULT") {
    // Dispatch the action to set it in the Redux store
    dispatch(setEffectiveAccountId(effectiveAccountId));
  }

  let isSavedConfigurator = shortId && !pathname.includes("config");
  if (isSavedConfigurator) {
    const savedConfiguration = await getSavedConfiguration({
      ...THREEKIT_PARAMS,
      configurationId: shortId,
    });
    const subcategory = savedConfiguration.metadata.activeSubcategory;

    let configPathname = savedConfiguration.metadata.pathname
      ? `${savedConfiguration.metadata.pathname}?shortId=${shortId}&effectiveAccountId=${effectiveAccountId}&inStore=${inStore}&subCategory=${subcategory}`
      : "/";
    window.location.href = configPathname;
  }
};
