import { DealerCategoryRestrictions } from "../../api/restrictions/types";

const labelToRestrictionKey: Record<string, string> = {
  Ottomans: "Ottomans",
  Chairs: "Accent Chairs",
  "Beds & Headboards": "Rails",
  Headboards: "Headboards",
  Rails: "Rails",
  Sofas: "Sofas",
  Sectionals: "Sectionals",
  "Accent Chairs": "Accent Chairs",
  "Accent Ottomans": "Accent Ottomans",
  "Accent Storage Ottomans": "Accent Storage Ottomans",
  // Add more mappings as needed
};

export const filterArrayByRestrictions = (
  original: any[],
  restrictions?: DealerCategoryRestrictions[],
  targetKey = "label"
) => {
  if (!restrictions || restrictions.length === 0) return original;

  const restrictionData = restrictions[0]; // Assume the first restriction object is used

  return original.filter((item) => {
    const key = item[targetKey];
    // Map the label to the corresponding restriction key
    const restrictionKey = labelToRestrictionKey[key] as keyof DealerCategoryRestrictions;
    // Include only items where the corresponding restriction is true
    return restrictionKey ? restrictionData[restrictionKey] === true : false;
  });
};
