import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddressLink from "../../components/AddressLink/AddressLink";
import ConfigurationInterface from "../../components/ConfigurationInterfaсe/ConfigurationInterfaсe";
import DeleteModel from "../../components/DeleteModel/DeleteModel";
import { FooterPlayer } from "../../components/FooterPlayer/FooterPlayer";
import { CustomizedState, Player } from "../../components/Player/Player";
import { TabsConfigurationInterface } from "../../components/TabsConfigurationInterface/TabsConfigurationInterface";
import Zoom from "../../components/Zoom/Zoom";
import { deleteAllPillows } from "../../redux/actions/player.action";
import { collectionsKeysT } from "../../redux/features/ui/ui.types";
import { changeConfigUI, setActiveColection, setInitialState } from "../../redux/features/ui/uiSlice";
import { getActiveCollectionModelsGroupsList, getLoadInit3kit } from "../../redux/selectors/ui.selector";
import { PAGES } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import s from "./SingleConfigurator.module.scss";

export default function SingleConfigurator() {
  const collectionModelsList = useAppSelector((state) => getActiveCollectionModelsGroupsList(state));
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = location.search;
  const dispatch = useAppDispatch();
  const isLoadInit3kit = useAppSelector((state) => getLoadInit3kit(state));
  const { hash, key, pathname, search, state } = useLocation();

  const locationState = useLocation().state as CustomizedState;
  const collectionId = !!locationState
    ? (locationState.collectionId as collectionsKeysT)
    : (pathname.split("/")[1] as collectionsKeysT);

  useEffect(() => {
    dispatch(changeConfigUI(collectionId));
    // store.dispatch(changeLoadInit3kitState(false));
    dispatch(setActiveColection(collectionId));
  }, []);

  useEffect(() => {
    if (!isLoadInit3kit && window.player.camera) {
      window.player.camera.frameBoundingSphere();
      window.player.camera.zoom(-2);
    }
  }, [isLoadInit3kit]);
  const isModelsList = collectionModelsList.length > 0;
  const goBack = () => {
    if (!isModelsList) dispatch(setInitialState());
    // navigate(isModelsList ? -1 : -2);
    if (isModelsList) {
      navigate(`/${collectionId}${queryParams}`, {
        state: { collectionId: collectionId },
      });
    } else {
      navigate(`/${collectionId}${queryParams}`);
    }
    dispatch(deleteAllPillows());
  };

  const handleClickToLogo = () => {
    dispatch(deleteAllPillows());
    dispatch(setInitialState());
    navigate(`${PAGES.index}${queryParams}`);
  };

  return (
    <div className={s.mainConfigurator}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={s.player}>
            <DeleteModel />
            <Zoom />
            <div className={s.player_header}>
              <div className={s.header}>
                <div className={s.logo} onClick={handleClickToLogo}>
                  <img src={"/images/assets/logo.png"} alt="" />
                </div>
              </div>
              <div className={s.back}>
                {/* <Back />
                <span>Change furniture</span> */}
                <AddressLink
                  playerTitle={collectionId.toLowerCase().includes("bed") ? "Beds & Headboards" : collectionId}
                  onClick={() => {
                    // window.player.unload()
                    goBack();
                  }}
                />
              </div>
              <div className={s.info__wrapper}>
                <div className={s.info}>Mix it up. Make it yours.</div>
                {/* <div className={s.ar}>
                  <ArSvg />
                  <span>View in my space</span>
                </div> */}
              </div>
              <TabsConfigurationInterface />
              {/* <div className={s.rotate_mob} onClick={openFullScreen}>
                <img
                  src="/images/assets/svg/RotateCamera.svg"
                  alt=""
                />
                <p>Use gesture to rotate or zoom</p>
              </div> */}
              {/* <div className={s.full_screen_mob} onClick={openFullScreen} >
                <FullScreen />
              </div> */}
            </div>
            <Player />
            <div className={s.player_footer}>
              <FooterPlayer />
            </div>
          </div>
          <ConfigurationInterface />
        </div>
      </div>
    </div>
  );
}
