import axios from "axios";
import { DealerCategoryAndFabricGradeRestrictions, DealerCategoryRestrictions } from "./types";

type RestrictionResponseData = {
  byCategory: DealerCategoryRestrictions[];
  bySubCatAndFabGrade: DealerCategoryAndFabricGradeRestrictions[];
};

type RestrictionsResponseT = {
  status: "Ok" | "Error";
  data: RestrictionResponseData;
  messages: string[];
};

const getRestrictions = async (effectiveAccountId: string): Promise<RestrictionResponseData> => {
  try {
    // Make a GET request to the API endpoint
    const response = await axios.get<RestrictionsResponseT>(`${process.env.REACT_APP_API_URL}/restrictions`, {
      params: { effectiveAccountId },
    });

    // Return the response data
    return response.data.data;
  } catch (error) {
    // Handle errors and provide a consistent error response
    console.error("Error fetching restrictions:", error);
    return {
      byCategory: [],
      bySubCatAndFabGrade: [],
    };
  }
};

export default getRestrictions;
