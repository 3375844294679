import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Yup from "yup";
import { getParamsForApiPriceInfo, sendMailInfo } from "../../../api";
import { instanceApiFunc } from "../../../api/instanceApi";
import { Close } from "../../../assets/svgCompopnent/Close";
import { openCloseModals } from "../../../redux/features/ui/uiSlice";
import { getPillows, getPillowsPrice, getThreekitModels } from "../../../redux/selectors/threekit.selectors";
import {
  checkIsHidePrice,
  getActiveCollectionId,
  getInfoModelsPrice,
  getModalInfo,
  getModelsPrice,
  getObjActiveParamsUI,
} from "../../../redux/selectors/ui.selector";
import { generatePDF } from "../../../service/pdf/generateLinkPDF";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { formatDate, isSectionalConfigurator, ucFirst } from "../../../utils/supportUtils";
import { ConfigForm } from "../EmailForm/ConfigForm";
import ModalsWrapper from "../ModalsWrapper/ModalsWrapper";
import { configurationParams } from "../PrintInfo/configurationParams";
import s from "./SaveConfig.module.scss";

export type infoUserT = {
  email: string[];
  name: string;
  sales_associate?: string;
};

export default function ModalSaveConfig() {
  const copyedTextInput: any = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [copied, setCopied]: any = useState(false);
  const pillows = useAppSelector((state) => getPillows(state));

  const priceModels = useAppSelector((state) => getModelsPrice(state));
  const pricePillows = useAppSelector((state) => getPillowsPrice(state));
  const isHidePrice = useAppSelector((state) => checkIsHidePrice(state));
  const threekitModels = useAppSelector(getThreekitModels);
  const infoModelsPrice = useAppSelector(getInfoModelsPrice);
  const objActiveParamsUI = useAppSelector((state) => getObjActiveParamsUI(state));
  const objActiveParams = useAppSelector(getObjActiveParamsUI);
  const activeCollection = useAppSelector(getActiveCollectionId);
  const requestData = getParamsForApiPriceInfo(activeCollection, objActiveParams);
  const configurationInfo = configurationParams(requestData, objActiveParamsUI.activeTab);
  const [dealerName, setDealerName] = useState("N/A");

  const [products, setProducts] = useState([]);

  const { isOpen, data }: any = useAppSelector((state) => getModalInfo(state)("SaveConfig"));
  const [sendText, setSendText] = React.useState("Send");

  async function openModalInfo(actualPrice: any) {
    let allProducts: any = {};
    let array: any = [];

    if (isSectionalConfigurator()) {
      threekitModels.map((item: any) => {
        setDealerName(actualPrice[item.name].dealerName);
        if (allProducts[item.name]) {
          allProducts[item.name].count = allProducts[item.name].count + 1;
        } else {
          const name = item.name;
          const price = actualPrice[item.name].price;
          const sku = actualPrice[item.name].skuFull;
          const count = 1;
          allProducts[item.name] = {
            name,
            price,
            sku,
            count,
            hidePillowMaterial: true,
          };
        }
      });
      Object.keys(allProducts).forEach((key) => {
        array.push(allProducts[key]);
      });
    } else {
      Object.keys(actualPrice).forEach((key) => {
        setDealerName(actualPrice[key].dealerName);
        let name = key;
        if (objActiveParamsUI.activeTab === "Headboards Only") {
          name = objActiveParamsUI.activeTab;
        }

        array.push({
          name: name,
          price: actualPrice[key].price,
          sku: actualPrice[key].skuFull,
          count: 1,
          hidePillowMaterial: true,
        });
      });
    }

    if (pillows.length >= 1) {
      const obgPillows: any = {};

      pillows.map((item: any, index) => {
        if (obgPillows[`${item.name}_${item.material}`]) {
          obgPillows[`${item.name}_${item.material}`] = {
            ...obgPillows[item.name],
            price: obgPillows[item.name].price + item.price,
            count: obgPillows[item.name].count + 1,
          };
        } else {
          obgPillows[`${item.name}_${item.material}`] = {
            name: item.name,
            price: item.price,
            sku: item.skuFull,
            material: ucFirst(item.material.replace("_", " ").replace("_", " ").replace("_", " ").toLowerCase()),
            hidePillowMaterial: false,
            count: 1,
          };
        }
      });
      Object.keys(obgPillows).forEach((key) => {
        array.push({
          name: obgPillows[key].name,
          price: obgPillows[key].price,
          sku: obgPillows[key].sku,
          count: obgPillows[key].count,
          material: obgPillows[key].material,
          hidePillowMaterial: obgPillows[key].hidePillowMaterial,
        });
      });
    }

    setProducts(array);
  }

  const sendEmailWithConfigurationInfo = async (info: infoUserT) => {
    dispatch(openCloseModals({ name: "MailSent", data: {} }));
    const name = info.name;
    const emails = info.email;
    const salesAssociate = info.sales_associate || "N/A";
    let copyTextInput = copyedTextInput.current.value;
    const price = priceModels + pricePillows;

    const instanceApi = instanceApiFunc({
      authToken: process.env.REACT_APP_API_TOKEN || "",
      urlApi: process.env.REACT_APP_API_URL || "/api",
    });

    emails.forEach(async (email: any) => {
      const pdfPayload = {
        salesAssociate,
        customerName: name,
        customerEmail: email,
        date: formatDate(new Date()),
        price: price,
        dealerName: dealerName,
        snapshotImage: data.snapshotURL,
        configurationProducts: products.map((i: any, index) => {
          if (index === 0) {
            return {
              ...i,
              snapshot: data.snapshotURL,
              configurationInfo: Object.entries(configurationInfo).map(([key, value]) => ({
                key: value.name,
                value: value.value,
              })),
            };
          }
          return i;
        }),
      };

      const pdfLink = await generatePDF(instanceApi, pdfPayload);

      const formData = {
        name,
        email,
        image: "snapShot",
        link: copyTextInput,
        pdfLink: pdfLink,
        snapshotURL: data.snapshotURL,
        price: isHidePrice ? 0 : price,
      };

      sendMailInfo(instanceApi, formData).then((resultUpdate: any) => {
        if (resultUpdate.Message == "OK") {
          setSendText("Sent");

          setTimeout(() => {
            dispatch(openCloseModals({ name: "MailSent", data: {} }));
            setSendText("Send");
            dispatch(openCloseModals({ name: "MailSent", data: {} }));
          }, 4000);
        }
      });
    });
  };
  const emailSchema = Yup.string().email("Invalid email format");

  const firstEmailSchema = emailSchema.required("Email is required");

  const any1: any = Yup.lazy((value: any, options: any) => {
    // валидация первого элемента массива
    if (options.path === "email[0]") return firstEmailSchema;

    // валидация всех остальных элементов массива
    return emailSchema;
  });

  const QuoteConfigSchema = Yup.object().shape({
    email: Yup.array().of(any1),
    name: Yup.string(),
  });

  useEffect(() => {
    openModalInfo(infoModelsPrice);
  }, [infoModelsPrice]);

  return (
    <>
      {isOpen && (
        <ModalsWrapper>
          <div className={s.modalSaveConfig}>
            <div className={s.close} onClick={() => dispatch(openCloseModals({ name: "SaveConfig" }))}>
              <Close />
            </div>
            <h2 className={s.title}>Save Configuration link</h2>
            <p className={s.subTitle}>Send via email to save / share your current configuration</p>
            {Object.keys(infoModelsPrice).length > 0 &&
              Object.entries(infoModelsPrice).map(([key, value], index) => {
                console.log(key, value);
                return (
                  <div key={key} className={s.productSKU}>
                    Your {key} SKU: <span className={s.productSKUValue}>{value.skuFull}</span>
                  </div>
                );
              })}
            <div className={s.formWrap}>
              <Formik
                initialValues={{ email: [""], name: "" }}
                validationSchema={QuoteConfigSchema}
                onSubmit={(values) => {
                  sendEmailWithConfigurationInfo(values);
                }}
              >
                {({ errors, touched, values, handleChange }) => <ConfigForm errors={errors} />}
              </Formik>
            </div>
            <p className={s.smallText}>Easily return to or share this configuration with the link below.</p>
            <div className={s.linkWrap}>
              <input
                ref={copyedTextInput}
                type="text"
                value={!!data ? data["linkText"] : ""}
                readOnly={true}
                className={s.linkText}
              />
              <CopyToClipboard text={data["linkText"]} onCopy={() => setCopied(true)}>
                <button className={s.btnCopyLink}>
                  <img src="/images/assets/svg/CopyTextIcon.svg" alt="" />
                  {!copied ? "Copy link" : "Link Copied"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </ModalsWrapper>
      )}
    </>
  );
}
