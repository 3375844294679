import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./StandartOption.module.scss";

import { changeThreekitConfiguration, deletePillow, setStarModelObj } from "../../redux/actions/player.action";
import {
  changeLoadPlayerState,
  openCloseModals,
  setActivePillow,
  setActiveSubcategory,
  setActiveTab,
} from "../../redux/features/ui/uiSlice";
import { getAllModels } from "../../redux/selectors/model.selector";
import { getPillows } from "../../redux/selectors/threekit.selectors";
import {
  getActiveCollectionId,
  getActiveSubcategory,
  getActiveTab,
  getAllThreekitAtribute,
  getCountAssets,
  getEffectiveAccountId,
  getIsInStore,
  getNameImageForHeadboard,
  getUserType,
} from "../../redux/selectors/ui.selector";
import { PAGES } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { isSectionalConfigurator } from "../../utils/supportUtils";
import { getImageSrcForStandartOption } from "../../utils/ui/utilsForFiles";
import InfoModal from "../InfoModal/InfoModal";

import { pillowBasicMoveHint } from "../../function/pillows/pillowBasicMoveHint";
import { getMaxPillowCurentSofaRelativeThreekit } from "../../function/pillows/pilowThreekitFunc";
import { CounterOption } from "../OptionComponents/CounterOption/CounterOption";

const addButton = () => {
  return (
    <span className={s.option_addButton}>
      <img src="/images/assets/svg/Plus.svg" alt="" />
      <span>Add</span>
    </span>
  );
};

export const StandartOption = ({ ...props }) => {
  const {
    id,
    parentId,
    label,
    imageLabel,
    name,
    visible,
    enabled,
    img,
    item,
    activeValue,
    title,
    link,
    styleItem,
    disable,
    value,
    buttonText,
    disableAllButtons,
    changeOptional,
    otherOptional,
    nameValueThreekit,
    popupInfo,
    metadata,
    description,
    Clarification,
    subcategory,
  } = props;

  let optionTitle = !!title ? title : label;

  // переробити аби ці нейми тянулись з метадати коли на 3кіті обновиться  інфа
  // і метадата буде показуватись
  if (label === "Classic") {
    optionTitle = "Classic - 38”";
  } else if (label === "Lounge") {
    optionTitle = "Lounge - 42”";
  }

  let actualpopupInfo = popupInfo;
  // if (metadata && metadata.popupInfo) {
  //   actualpopupInfo = metadata.popupInfo;
  // }
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const effectiveAccoundId = useAppSelector(getEffectiveAccountId);
  const inStore = useAppSelector(getIsInStore);
  const userType = useAppSelector(getUserType);

  const [isPopupShown, setIsPopupShown] = useState(false);
  let activeCollectionId = useAppSelector(getActiveCollectionId);
  let allThreekitAtribute = useAppSelector((state) => getAllThreekitAtribute(state));
  const allModels = useAppSelector(getAllModels);
  const activeTab = useAppSelector((state) => getActiveTab(state));

  const nameImageForHeadboard = useAppSelector((state) => getNameImageForHeadboard(state)(parentId, optionTitle));
  const pillowsThreekit = useAppSelector(getPillows);
  const subcategoryStore = useAppSelector(getActiveSubcategory);

  const handleClick = (name: string, value: string) => {
    dispatch(setActiveSubcategory(subcategory || subcategoryStore));
    console.log("active subcategory", subcategory);
    if (isSectionalConfigurator()) {
      otherOptional && otherOptional({ name, value, item });
      if (!!link) {
        dispatch(changeLoadPlayerState(true));

        if (Object.keys(allModels).length > 0) {
          dispatch(setStarModelObj(nameValueThreekit));
        } else {
          window.constantsUI = { ["modelName"]: nameValueThreekit };
        }

        navigate(
          PAGES.config +
            `?effectiveAccountId=${effectiveAccoundId}&inStore=${inStore}&userType=${userType}&subCategory=${subcategory}`,
          {
            state: {
              collectionId: activeCollectionId,
              nameThreekitArtibuteModel: nameValueThreekit,
            },
          }
        );
      } else {
        if (item.tags && item.tags.includes("pillow")) {
          const isPillowInBasicPosition = pillowBasicMoveHint(pillowsThreekit);
          if (isPillowInBasicPosition) {
            dispatch(openCloseModals({ name: "PillowMoveHint" }));
          } else {
            changeOptional({ name, value, item });
          }
        } else {
          changeOptional({ name, value, item });
        }
      }
    } else {
      if (!!link) {
        dispatch(changeLoadPlayerState(true));
        if (parentId === "item_type") {
          window.sessionStorage.setItem("itemType", nameValueThreekit);
        }
        if (!parentId.includes("bed")) {
          if (Object.keys(allThreekitAtribute).length > 0) {
            dispatch(changeThreekitConfiguration({ [parentId]: nameValueThreekit }));
          } else {
            window.constantsUI = { ["modelName"]: nameValueThreekit };
          }

          navigate(
            PAGES.config +
              `?effectiveAccountId=${effectiveAccoundId}&inStore=${inStore}&userType=${userType}&subCategory=${subcategory}`,
            {
              state: {
                collectionId: activeCollectionId,
                nameThreekitArtibuteModel: nameValueThreekit,
              },
            }
          );
        }
      } else {
        changeOptional({ name, value });
      }
      if (activeCollectionId === "bed" && pathname.split("/").length < 3) {
        dispatch(setActiveTab("Beds"));
        let type = "bed";

        if (nameValueThreekit.includes("headboard")) {
          type = "headboard";
          dispatch(setActiveTab("Headboards Only"));
        } else {
          dispatch(setActiveTab("Beds"));
        }

        navigate(
          PAGES.config +
            `?effectiveAccountId=${effectiveAccoundId}&inStore=${inStore}&userType=${userType}&subCategory=${subcategory}`,
          {
            state: {
              collectionId: activeCollectionId,
              nameThreekitArtibuteModel: nameValueThreekit.includes("headboard")
                ? nameValueThreekit.split("_")[1]
                : nameValueThreekit,
              type,
            },
          }
        );
      }
    }
  };

  const imgTitle = imageLabel ? imageLabel : optionTitle;
  let imageSrc: any = getImageSrcForStandartOption(parentId, imgTitle, activeTab, pathname, nameImageForHeadboard);

  const count = useAppSelector(getCountAssets(parentId, id));

  // check acrtive value
  const isActive = count > 0 || (name || label) === activeValue;

  const isDisabled = () => {
    if (enabled === undefined) return false;
    return disable || !enabled || disableAllButtons;
  };

  const isShowPillowsCountControl = () => {
    const isPillows = parentId === "pillows";

    const numberMaxPillowCurentSofaRelativeThreekit = getMaxPillowCurentSofaRelativeThreekit();

    return (
      isPillows &&
      isActive &&
      !isSectionalConfigurator() &&
      pillowsThreekit.length >= numberMaxPillowCurentSofaRelativeThreekit + 1
    );
  };

  const handleCountControl = (
    e: React.MouseEvent<HTMLElement>,
    sign: "plus" | "minus",
    name: string,
    value: string
  ) => {
    e.stopPropagation();
    if (sign === "plus") {
      changeOptional({ name, value });
    }
    if (sign === "minus") {
      if (isSectionalConfigurator()) {
        const filterForPillowByName = pillowsThreekit
          .filter((pillow) => pillow.id.includes(name))
          //@ts-ignore
          .at(-1);

        if (filterForPillowByName) {
          window.player.selectionSet.set(filterForPillowByName.id);
        }
      }

      dispatch(setActivePillow(name));
      dispatch(deletePillow(name));
    }
  };

  let classStandartOption = `${s.option_item}`;
  if (styleItem) classStandartOption += ` ${styleItem}`;
  if (isActive) classStandartOption += ` ${s.option_item_active}`;
  if (isDisabled()) classStandartOption += ` ${s.disable}`;

  return (
    <div onClick={() => handleClick(name, value)} className={classStandartOption}>
      <div className={`${s.option_img} ${isShowPillowsCountControl() && s.small_img}`}>
        <CounterOption parentId={parentId} id={id} />
        <img src={imageSrc} alt="" />
      </div>
      <div
        className={`${s.option_title} ${
          isShowPillowsCountControl() && s.small_title
        } ${actualpopupInfo && s.limited_width}`}
      >
        {optionTitle}
      </div>

      {description && <div className={s.description}>{description}</div>}
      {Clarification && <div className={s.Clarification}>{<Clarification />}</div>}
      {isShowPillowsCountControl() && (
        <div className={s.option_countControlWrap}>
          {/* <button
            className={`${s.countPlus} ${isDisabled() && s.disabled}`}
            onClick={(e) => handleCountControl(e, "plus", name, value)}
          >
            <img
              src="/images/assets/svg/PlusCircle.svg"
              alt=""
            />
          </button> */}
          <button className={`${s.countMinus}`} onClick={(e) => handleCountControl(e, "minus", name, value)}>
            <img src="/images/assets/svg/MinusCircle.svg" alt="" />
          </button>
        </div>
      )}

      {actualpopupInfo && (
        <div id={id} className={s.option_infoIconWrapper}>
          <img
            id={id + "img"}
            src="/images/assets/icon-info.svg"
            alt="icon-info"
            onClick={() => setIsPopupShown(!isPopupShown)}
          />
        </div>
      )}

      {actualpopupInfo && isPopupShown && (
        <InfoModal
          infoText={actualpopupInfo}
          isPopupShown={isPopupShown}
          setIsPopupShown={setIsPopupShown}
          infoIconId={id}
        />
      )}
    </div>
  );
};
