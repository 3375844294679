import { getSavedConfiguration } from "../api/api";
import {
  addAllModels,
  addPillowInState,
  changeThreekitConfiguration,
  deleteAllPillowsInState,
  setNewThreekitState,
  setStarModelObj,
  updateModelsPrice,
} from "../redux/actions/player.action";
import { objSetConfigurationT } from "../redux/features/threekit/threekit.types";
import {
  changeConfigUI,
  changeLoadInit3kitState,
  changeLoadPlayerState,
  setActiveColection,
  setAllThreekitAtributes,
  setSavedConfiguration,
} from "../redux/features/ui/uiSlice";
import {
  getActiveParamsById,
  getCollectionNameFromAssetId,
  getModelsGroupIdByCollectionId,
  getObjActiveParamsUI,
  isSectionConfiguratorUI,
} from "../redux/selectors/ui.selector";
import { store } from "../redux/store";
import { TYPE_REDUCER } from "./constants";
import { getActualClassnameForArBtn, isSectionalConfigurator } from "./supportUtils";
import { onAnnotationChange } from "./threekit/annotationStyle";
import {
  getDisplayAttributes,
  getObjectUniqueMaterialModels,
  updateMaterialInModel,
  updateMaterialPillow,
} from "./threekit/attributesFunctions";
import { setAllPlane } from "./threekit/pillows/planeFuncition";
import { setConfiguration, setObjConfiguration } from "./threekit/threekitFunc";
import { addNewNodePillow, addNewNodePoint, addNodeModel } from "./threekit/threekitModels";
import { clickTools } from "./threekit/threekitTools";
import { getFullObjForUpdateThreekit } from "./ui/utilsForData";

export function getSectionalModels() {
  const allmodels: any = getDisplayAttributes().find((e: any) => e.name === "Models");

  const trueColectionName = "Sectional";
  const models = allmodels.values.filter((m: any) => {
    const tag = m.tags.find((e: string) => e === trueColectionName);

    if (tag) {
      return true;
    }
    return false;
  });
  return models;
}
export const init3kit = async ({ playerEl, idConfig, THREEKIT_PARAMS, dispatch, collection }: any) => {
  if (!playerEl) return false;

  let savedConfiguration: any | null = null;

  if (idConfig) {
    savedConfiguration = await getSavedConfiguration({
      ...THREEKIT_PARAMS,
      configurationId: idConfig,
    });
  }

  if (!!!THREEKIT_PARAMS["assetId"]) {
    THREEKIT_PARAMS["assetId"] = savedConfiguration["productId"];
    collection = getCollectionNameFromAssetId(store.getState())(THREEKIT_PARAMS["assetId"]);

    if (!!collection) {
      dispatch(changeConfigUI(collection));
      dispatch(setActiveColection(collection));
    }
  } else if (savedConfiguration && THREEKIT_PARAMS["assetId"].length === 2) {
    const activeTab = savedConfiguration.metadata.objectActiveParams.activeTab;
    if (activeTab === "Beds") {
      THREEKIT_PARAMS["assetId"] = THREEKIT_PARAMS["assetId"][0];
    } else if (activeTab === "Headboards Only") {
      THREEKIT_PARAMS["assetId"] = THREEKIT_PARAMS["assetId"][1];
    }
  }

  const isNotBedCollection = collection !== "bed";

  const prevSesstion: any = window.sessionStorage.getItem("store");
  const itemType: any = window.sessionStorage.getItem("itemType");

  const sessionStore = JSON.parse(prevSesstion);
  const checkIsReloadSectionalConfigurator =
    !savedConfiguration && sessionStore.variant && !idConfig && isSectionalConfigurator();
  if (checkIsReloadSectionalConfigurator) {
    savedConfiguration = sessionStore;
  }

  const arBtnClasses = getActualClassnameForArBtn();
  //@ts-ignore
  if (window.threekitPlayer) {
    //@ts-ignore
    console.log("start Load_THREEKIT");

    const params = {
      authToken: THREEKIT_PARAMS["authToken"],
      el: playerEl,
      assetId: THREEKIT_PARAMS["assetId"],
      // initialConfiguration: {},
      showAR: true,
      publishStage: "published",
      classnames: {
        ar: arBtnClasses,
      },
      // initialConfiguration: {},
      // initialConfiguration: isNotBedCollection
      // ? savedConfiguration?.variant

      // : {},
      // initialConfiguration: {["Sofa type"]: {}}, // savedConfiguration?.variant
      // showConfigurator: true,
      //   display: "webgl",
      onAnnotationChange: (annotations: any, parentEl: any) => onAnnotationChange(annotations, parentEl, dispatch),
    };

    window
      //@ts-ignore
      .threekitPlayer(params)
      .then(async (api: any) => {
        //@ts-ignore
        await api.when("loaded");
        window.player = api;
        // @ts-ignore
        // window.rootId = await getRootId(api);

        //@ts-ignore
        window.playerT = await api.enableApi("player");

        const instanceId = window.player.scene.get({
          from: window.player.instanceId,
          evalNode: true,
        });
        // && isSectionalConfigurator()
        if (instanceId.Proxy && isSectionalConfigurator()) {
          const instanceParentId = window.player.scene.get({
            from: instanceId.Proxy.proxy.instanceId,
            evalNode: true,
          });
          // @ts-ignore
          window.parrentPlayer = instanceParentId;
        }
        window.configurator = await api.getConfigurator();

        // if (isSectionalConfigurator()) {
        //   // @ts-ignore
        //   window.configurator = window.parrentPlayer.configurator;
        // } else {
        //   window.configurator = await api.getConfigurator();
        // }
        window.player.tools.addTool(clickTools(dispatch, collection));

        if (!savedConfiguration) {
          // з стейту трикіта аллМоделс перенести в ui setAllThreekitAtributes

          if (isSectionalConfigurator()) {
            //  @ts-ignore
            const models = getSectionalModels();
            dispatch(addAllModels({ models }));

            // dispatch(
            //   setAllThreekitAtributes(models)
            // );
            // LS
            getObjectUniqueMaterialModels(store.getState()).then((materilaModels) => {
              store.dispatch(
                setAllThreekitAtributes({
                  arrAllThreekitAtributes: materilaModels,
                })
              );
            });

            // let startAsset = startModel ? startModel : "d5d53809-a702-4bc3-954b-dacf7be5fdb0"; // For Sectional
            if (!!window["constantsUI"] && !!window["constantsUI"]["modelName"]) {
              dispatch(setStarModelObj(window["constantsUI"]["modelName"]));
            }
          } else {
            store.dispatch(
              setAllThreekitAtributes({
                arrAllThreekitAtributes: getDisplayAttributes(),
              })
            );

            if (!!window.constantsUI && !!window.constantsUI["modelName"]) {
              const modelsGroupId = getModelsGroupIdByCollectionId(store.getState())(collection);
              dispatch(
                changeThreekitConfiguration({
                  [modelsGroupId]: window["constantsUI"]["modelName"],
                })
              );
            }

            if (itemType !== "") {
              dispatch(changeThreekitConfiguration({ item_type: itemType }));
            }
          }
        } else {
          dispatch(changeLoadInit3kitState(true));

          let metadata = savedConfiguration.metadata;

          if (checkIsReloadSectionalConfigurator) {
            dispatch(changeLoadInit3kitState(false));

            metadata = {
              objectActiveParams: metadata.ui.configUI.objectActiveParams,
              allThreekitAtributes: metadata.ui.allThreekitAtributes,
              models: metadata.threeKit.models,
              pillows: metadata.threeKit.pillows,
              threeKit: metadata.threeKit,
            };
          }
          // const configurator = await window.player.getConfigurator();
          // configurator.setConfiguration(savedConfiguration.variant)

          await setObjConfiguration(savedConfiguration.variant);

          console.log("savedConfiguration.variant: ", savedConfiguration.variant);
          dispatch(setSavedConfiguration(metadata));

          if (isSectionalConfigurator() || isSectionConfiguratorUI(store.getState())) {
            // Sectional configurator START
            Object.keys(metadata.threeKit.models).forEach((key: string) => {
              const model = metadata.threeKit.models[key];

              addNodeModel(model.assetId, model.transform.translation, model.transform.rotation, model.id);
            });
            const obgModels: any = [];
            Object.keys(metadata.threeKit.models).forEach((key: string) => {
              const model = metadata.threeKit.models[key];
              obgModels.push({ assetId: model.assetId });
            });
            // setModelsForSectionalConfigurator(Object.values(obgModels));
            setConfiguration("Models", obgModels);
            //   {
            //     "pos_first_0#": "20643e44-fecf-437f-b01d-c8fc3ed37322",
            //     "pos_positive_1#": "381ee70b-92ab-4ef1-baa7-6c479f2f223c",
            //     "pos_negative_1#": "9d3154dd-2278-4d62-a0b5-c498850007b6",
            //     "pos_negative_2#": "d5d53809-a702-4bc3-954b-dacf7be5fdb0",
            //     "pos_negative_3#": "3966831e-8986-40bc-8396-fa4057d98b63"
            // }

            Object.keys(metadata.threeKit.newPoints).forEach((key: string) => {
              const point = metadata.threeKit.newPoints[key];
              addNewNodePoint(key, point.translation);
            });

            dispatch(setNewThreekitState(metadata.threeKit));
            dispatch({ type: TYPE_REDUCER.UPDATE_STORE });

            // Set option for models
            const objectActiveParams = metadata["objectActiveParams"];
            const objUpdatedAttributes: objSetConfigurationT = getFullObjForUpdateThreekit(
              store.getState(),
              objectActiveParams
            );
            updateMaterialInModel(Object.values(metadata.threeKit.models))(objUpdatedAttributes).then((e: any) => {
              dispatch(changeLoadPlayerState(false));
              const storeCopy = JSON.parse(JSON.stringify(store.getState()));

              const objActiveParams = getObjActiveParamsUI(storeCopy);
              store.dispatch(
                changeThreekitConfiguration({
                  fabric: objActiveParams.fabric,
                })
              );
            });

            dispatch(deleteAllPillowsInState());
            const newPillowsArray: any[] = [];
            const pillows = metadata?.pillows_transform || [];
            if (pillows.length >= 1) {
              Object.keys(pillows).forEach((key, index) => {
                const pillow = pillows[key];
                const id = addNewNodePillow(
                  `Pillows_${pillow.name}_${Math.random() * 100}`,
                  pillow.transform[0].translation,
                  pillow.transform[0].rotation,
                  pillow.assetId
                );
                pillow["id"] = id;
                newPillowsArray.push(pillow);
                dispatch(addPillowInState(pillow));
              });
              updateMaterialPillow(newPillowsArray).then(async () => {
                dispatch(changeLoadPlayerState(false));
              });
            }
            setTimeout(() => {
              setAllPlane();
            }, 4000);
            // Sectional configurator END
          } else {
            dispatch(setNewThreekitState(metadata.threeKit));
            dispatch(deleteAllPillowsInState());
            const pillows = metadata.threeKit.pillows;
            Object.keys(pillows).forEach((key, index) => {
              const pillow = pillows[key];
              if (index <= 2) {
                const id = window.player.scene.find({
                  from: window.player.player.api.instanceId,
                  hierarchical: true,
                  name: `Pillow ${index + 1}`,
                });
                pillow["id"] = id[0];
              }

              dispatch(addPillowInState(pillow));
            });
          }
          window.player.camera.frameBoundingSphere();
          dispatch(updateModelsPrice());
        }

        // // todo прибрати це після того як для ліжок додасться екран вибору ліжок або узголів'я
        // if (!isNotBedCollection && !savedConfiguration) {
        //   dispatch(updateModelsPrice());
        // }

        window.player.on("rendered", () => {
          dispatch(changeLoadInit3kitState(false));
          dispatch(changeLoadPlayerState(false));

          window.player.camera.frameBoundingSphere();
          window.player.camera.zoom(-2);

          const bed_size = getActiveParamsById(store.getState())("bed_size");
          const item_type = getActiveParamsById(store.getState())("item_type");

          if (bed_size) {
            const allSize = window.playerT.configurator
              .getDisplayAttributes()
              .find((item: any) => item.name === "Bed Frame");
            const actualSize = allSize.value.assetId;
            const UISize = allSize.values.find((item: any) => item.name === bed_size);
            if (actualSize !== UISize.assetId) {
              window.configurator.setConfiguration({
                "Bed Frame": {
                  assetId: UISize.assetId,
                },
              });
            }
          }
          if (item_type) {
            const allType = window.playerT.configurator
              .getDisplayAttributes()
              .find((item: any) => item.name === "Sofa type");
            const actualType = allType.value.assetId;
            const UIType = allType.values.find((item: any) => item.name === item_type);
            if (actualType !== UIType.assetId) {
              window.configurator.setConfiguration({
                "Sofa type": {
                  assetId: UIType.assetId,
                },
              });
            }
          }
        });
      });
  }
};
