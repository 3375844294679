import { createSelector } from "@reduxjs/toolkit";
import { DealerCategoryAndFabricGradeRestrictions } from "../../api/restrictions/types";
import { collectionsMainMenu } from "../../utils/Constants/Collections";
import { modelsBedroom } from "../../utils/Constants/modelsBedroom";
import { isSectionalConfigurator, refactoringPillowsLabel } from "../../utils/supportUtils";
import { getAttribute, getObjValueAttributeThreekit } from "../../utils/threekit/attributesFunctions";
import { sortValueRelativeMetadaThreekit } from "../../utils/threekit/threekitFunc";
import {
  collectionsKeysT,
  collectionUIT,
  modalsName,
  modelSectionalT,
  objectActiveParamsT,
  tabsCollectionT,
} from "../features/ui/ui.types";
import { RootState, RootStateUI } from "../store";
import { getCountActiveModels } from "./model.selector";
import { getPillows, getPillowsPrice, getThreekitModels } from "./threekit.selectors";

export const getUIModel = (state: RootState, assetId: string) => {
  return state.ui.models.find((model) => model.assetId === assetId)?.name;
};
export const getAllUIModel = (state: RootState) => {
  return state.ui.models;
};
// export const getCollection =
//   (state: RootState) => (name: string | undefined) => {
//     const collection = state.ui.collections.find((cl) => cl.name === name);

//     if (collection) {
//       return collection.threekitTag;
//     }

//     return "";
//   };

// export const getStartAssetId =
//   (state: RootState) => (name: string | undefined) => {
//     const collection = state.ui.collections.find((cl) => cl.name === name);

//     if (collection) {
//       return collection.startAssetId;
//     }

//     return "";
//   };

// Додати більше таких селекторів
export const getAllCollections = (state: RootState) => {
  return state.ui.collection.objListCollections;
};

export const getAllPagesModels = (state: RootState) => {
  return state.ui.collection.objPageModels;
};

export const getCartInfo = (state: RootState) => {
  return state.ui.cartInfo;
};

export const getAllThreekitAtribute = (state: RootState) => {
  return state.ui.allThreekitAtributes;
};

export const getObjActiveParamsUI = (state: RootState) => {
  return state.ui.configUI.objectActiveParams;
};

export const getObjectDependensThreekitRelativeUI = (state: RootStateUI) => {
  return state.ui.configUI.objectDependensThreekitRelativeUI;
};

export const getArrCameras = (state: RootStateUI) => {
  return state.ui.cameras.arrCameras;
};

export const getActiveCamera = (state: RootStateUI) => {
  return state.ui.cameras.activeCamera;
};

export const getInfoModelsPrice = (state: RootState) => {
  return state["ui"]["infoModelsPrice"];
};

export const getMenuMainCollectionsArr = (state: RootState) => {
  const allCollections = getAllCollections(state);
  const allCollectionsKeys = Object.keys(allCollections) as Array<keyof typeof allCollections>;
  const resultCollectionsArr: collectionUIT[] = [];
  allCollectionsKeys.forEach((collectionKey: collectionsKeysT) => {
    if (collectionsMainMenu.includes(collectionKey)) {
      resultCollectionsArr.push(allCollections[collectionKey]);
    }
  });
  return resultCollectionsArr;
};

export const getRestrictionByCategory = (state: RootState) => {
  return state.ui.categoryRestrictions;
};

export const getActiveSubcategory = (state: RootState) => state.ui.activeSubcategory;
export const getRestrictionsBySubCategoryAndGrade = (state: RootState) => state.ui.subcatAndFabGradeRestrictions;

export const getEffectiveAccountIdSubcatAndGradeRestrictions = createSelector(
  [getActiveSubcategory, getRestrictionsBySubCategoryAndGrade],
  (activeSubcategory, subcatAndFabGradeRestrictions) => {
    return subcatAndFabGradeRestrictions?.find((i) => i.SubCategory === activeSubcategory);
  }
);

export const getIsPillowAvailable = (state: RootState) => {
  if (!state.ui?.categoryRestrictions?.[0]) return true;

  return state.ui?.categoryRestrictions?.[0]?.Pillows;
};

export const getSubMenuCollectionsArr = (state: RootState) => {
  const allCollections = getAllCollections(state);
  const allCollectionsKeys = Object.keys(allCollections) as Array<keyof typeof allCollections>;
  const resultCollectionsArr: collectionUIT[] = [];
  allCollectionsKeys.forEach((collectionKey: collectionsKeysT) => {
    if (!collectionsMainMenu.includes(collectionKey)) {
      resultCollectionsArr.push(allCollections[collectionKey]);
    }
  });
  return resultCollectionsArr;
};

export const getActiveCollectionId = (state: RootStateUI) => {
  return state.ui.collection.activeCollection;
};

export const getActiveCollectionObj = (state: RootState) => (activeColectionId: collectionsKeysT) => {
  const allCollections = getAllCollections(state);
  return allCollections[activeColectionId];
};

export const isSectionConfiguratorUI = (state: RootStateUI) => {
  const activeCollection = getActiveCollectionId(state);
  return activeCollection === "sectional";
};

export const getActiveCollectionAssetId = (state: RootState) => {
  const allCollections = getAllCollections(state);
  const activeColectionId = getActiveCollectionId(state);
  return allCollections[activeColectionId].aseetId;
};

export const getCollectionNameFromAssetId =
  (state: RootState) =>
  (assetId: string): collectionsKeysT | undefined => {
    const allCollections = getAllCollections(state);
    const allCollectionsKeys = Object.keys(allCollections) as Array<keyof typeof allCollections>;
    const collectionName = allCollectionsKeys.find((collectionKey: collectionsKeysT) => {
      return allCollections[collectionKey]["aseetId"] === assetId;
    });

    return collectionName;
  };

export const getActiveCollectionModelsGroupsList = (state: RootState) => {
  const activeColectionId = getActiveCollectionId(state);
  const allPagesModels = getAllPagesModels(state);

  return allPagesModels[activeColectionId];
};

// Get OBJ AssetId
export const getObjAssetIdByNameAttributeForModels = (state: RootState) => (nameAttribute: string) => {
  const allThreelikAtribute = getAllThreekitAtribute(state);
  const sofaTypeAttributeValues = allThreelikAtribute["Sofa type"]["values"];
  const filterSofaTypeAttributeValues = sofaTypeAttributeValues.filter(
    (valueObj: any) => valueObj["name"] === nameAttribute
  );

  return { assetId: filterSofaTypeAttributeValues["assetId"] };
};

export const getObjAssetIdFromThreekit = (state: RootState) => (nameAttribute: string, value: string) => {
  const allThreelikAtribute = getAllThreekitAtribute(state);
  if (!!!allThreelikAtribute[nameAttribute]) return null;
  const attributeValues = allThreelikAtribute[nameAttribute]["values"];
  if (attributeValues.length === 0) return null;
  const filterAttributeValues = attributeValues.filter((valueObj: any) => {
    if (!!valueObj["name"] && valueObj["name"] === value) {
      return true;
    } else if (!!valueObj["value"] && valueObj["value"] === value) {
      return true;
    }

    return false;
  });

  if (filterAttributeValues.length === 0) return null;

  let result;
  const assetId = !!filterAttributeValues[0]["assetId"] ? filterAttributeValues[0]["assetId"] : null;

  if (isSectionalConfigurator()) {
    if (filterAttributeValues[0]["assetId"]) {
      result = { assetId: assetId };
    } else {
      result = filterAttributeValues[0]["value"];
    }
  } else {
    result = { assetId: assetId };
  }
  return result;
};
export const getValueFromThreekit = (state: RootState) => (nameAttribute: string, value: string) => {
  const allThreekitAtributes = getAllThreekitAtribute(state);

  if (!!!allThreekitAtributes[nameAttribute]) return null;

  const attributeObj = allThreekitAtributes[nameAttribute];
  const attributeType = attributeObj["type"];
  const attributeValues = attributeObj["values"];

  if (attributeValues.length === 0 && attributeType !== "Boolean") return null;

  const filterAttributeValues = attributeValues.filter((valueObj: any) => {
    if (!!valueObj["name"] && valueObj["name"] === value) {
      return true;
    } else if (!!valueObj["value"] && valueObj["value"] === value) {
      return true;
    }

    return false;
  });

  if (filterAttributeValues.length === 0 && attributeType !== "Boolean") return null;

  let resultValue;

  switch (attributeType) {
    case "Asset":
      resultValue = { assetId: filterAttributeValues[0]["assetId"] };
      break;
    case "String":
      resultValue = filterAttributeValues[0]["value"];
      break;
    case "Boolean":
      // resultValue = !attributeObj["value"]
      resultValue = value;
      break;
    case "Array":
      const arrAttributeValuesCurrent = getAttribute(nameAttribute);
      resultValue = [...arrAttributeValuesCurrent, { assetId: filterAttributeValues[0]["assetId"] }];
      break;
    default:
      resultValue = null;
  }

  return resultValue;
};

// Selectors for Tabs
export const getActiveTab = (state: RootState) => {
  return state.ui.configUI.objectActiveParams["activeTab"];
};
// Selectors for Tabs
export const getActivelegType = (state: RootState) => {
  return state.ui.configUI.objectActiveParams["leg"];
};

export const getActiveParamsById = (state: RootState) => (id: any) => {
  return state.ui.configUI.objectActiveParams[id];
};

// Selectors for Tabs
export const getActiveCategory = (state: RootState) => {
  return state.ui.configUI.objectActiveParams["activeCategory"];
};
export const getCollectionTabs = (state: RootState) => {
  const objTabs = state.ui.configUI.componentsUI.objTabs;
  const allTabsKeys = Object.keys(objTabs) as Array<keyof typeof objTabs>;
  // const arrTabs = allTabsKeys.map((tabKey) => <objTabT>objTabs[tabKey]);
  return allTabsKeys;
};

export const getCollectionCategoryesByTab = (state: RootState) => (tab: any) => {
  //@ts-ignore
  const objTabs = state.ui.configUI.componentsUI.objTabs[tab];
  return objTabs;
};

export const getCollectionCategoryes = (state: RootState) => {
  //@ts-ignore
  const objTabs = state.ui.configUI.componentsUI.objTabs;
  return objTabs;
};

// Get objectDependensThreekitRelativeUI
export const getObjNameThreekitAtributeComponentIdUI = (state: RootState) => (idComponentUi: string) => {
  const objectDependensThreekitRelativeUI = getObjectDependensThreekitRelativeUI(state);
  const objectDependensThreekitRelativeUIKeys = Object.keys(objectDependensThreekitRelativeUI);
  let objNameThreekitAtributeComponentIdUI: any = {};
  objectDependensThreekitRelativeUIKeys.forEach((nameThreekitAtribut: string) => {
    if (objectDependensThreekitRelativeUI[nameThreekitAtribut].includes(idComponentUi)) {
      objNameThreekitAtributeComponentIdUI = {
        [nameThreekitAtribut]: objectDependensThreekitRelativeUI[nameThreekitAtribut],
      };
    }
  });
  return objNameThreekitAtributeComponentIdUI;
};

// Selectors for Accordidon
export const getTabAccordionsKeys = (state: RootState) => {
  const activeTab: tabsCollectionT = getActiveTab(state);
  const objTabs = state.ui.configUI.componentsUI.objTabs;
  const arrAccordionKeys = objTabs[activeTab];
  return arrAccordionKeys;
};
export const getAccordionsForActiveTab = (state: RootState) => {
  // debugger;
  const tabAccordionsKeys = getTabAccordionsKeys(state);
  const allAccordions = state.ui.configUI.componentsUI.accordions; //getAllAccordionsUI
  const isLoad = state.ui.loadConfig.loadPlayer; //getAllAccordionsUI
  const collectionId = getActiveCollectionId(state);

  // const attributes = window.configurator?.getDisplayAttributes();
  const arrayAttr = Object.keys(getAllThreekitAtribute(state));

  const newArray: any = [];
  const accordionsForActiveTab = tabAccordionsKeys?.map((accordionKey) => allAccordions[accordionKey]);

  accordionsForActiveTab?.map((setting) => {
    let mainItemId = setting["data"]["embeddedView"][0]["data"].id;

    const objectDependensThreekitRelativeUI = getObjNameThreekitAtributeComponentIdUI(state)(mainItemId);
    const threekitNameAttr = Object.keys(objectDependensThreekitRelativeUI)[0];
    if (collectionId === "ottoman") {
      if (arrayAttr.includes(threekitNameAttr)) {
        newArray.push(setting);
      }
    } else {
      newArray.push(setting);
    }
  });

  return newArray;
};

export const getComponentIdUIFromNameThreekitAtribute = (state: RootStateUI) => (nameThreekitAtribute: string) => {
  const objectDependensThreekitRelativeUI = getObjectDependensThreekitRelativeUI(state);
  const objectDependensThreekitRelativeUIKeys = Object.keys(objectDependensThreekitRelativeUI);
  let componentIdUI: string[] = [];
  objectDependensThreekitRelativeUIKeys.forEach((nameThreekitAtributeUI: string) => {
    if (nameThreekitAtributeUI === nameThreekitAtribute) {
      componentIdUI = objectDependensThreekitRelativeUI[nameThreekitAtributeUI];
    }
  });
  return componentIdUI[0];
};

// Checking the maximum number of cushions for Sofa Type for ClassicSofa
export const checkMaxCountPillows = (state: RootState) => (idComponentUi: string) => {
  const collectionId = getActiveCollectionId(state);
  const modelsGroupId = getModelsGroupIdByCollectionId(state)(collectionId);
  const objectDependensThreekitRelativeUI = getObjNameThreekitAtributeComponentIdUI(state)(modelsGroupId);
  const nameAttributeThreekit = Object.keys(objectDependensThreekitRelativeUI)[0];
  const nameValueAttributeThreekit = getActiveParamFromIdComponentUI(modelsGroupId)(state);
  const valueComponetntUI = getActiveParamFromIdComponentUI(idComponentUi)(state);

  if (!!nameValueAttributeThreekit) {
    const objValueAttributeThreekit = getObjValueAttributeThreekit(nameAttributeThreekit, nameValueAttributeThreekit);
    if (!!objValueAttributeThreekit["metadata"] && !!objValueAttributeThreekit["metadata"]["MaxPillowsCountUI"]) {
      return valueComponetntUI.length < objValueAttributeThreekit["metadata"]["MaxPillowsCountUI"];
    }
  }

  return true;
};

// To get the list of velos by component id
export const getValuesFromIdAttribute = (idAtribute: string) => (state: RootState) => {
  const activeItemType = getActiveParamsById(state)("item_type");
  const restrictionsData = getEffectiveAccountIdSubcatAndGradeRestrictions(state);

  const objNameThreekitAtributeComponentIdUI = getObjNameThreekitAtributeComponentIdUI(state)(idAtribute);

  const allThreekitAtributes = getAllThreekitAtribute(state);

  let values: any = [];
  const objAttribute = allThreekitAtributes[Object.keys(objNameThreekitAtributeComponentIdUI)[0]];

  if (
    Object.keys(objNameThreekitAtributeComponentIdUI).length > 0 &&
    Object.keys(allThreekitAtributes).length > 0 &&
    objAttribute
  ) {
    const valuesThreekit = objAttribute["values"];

    let copyValuesThreekitAttr = valuesThreekit.slice();

    copyValuesThreekitAttr = sortValueRelativeMetadaThreekit(valuesThreekit);

    const effectiveAccountId = getEffectiveAccountId(state);
    const location = window.location.pathname.split("/");

    copyValuesThreekitAttr.map((value: any) => {
      let name = value.name !== undefined ? value.name : value.label;

      let label = value.label;
      if (idAtribute === "fabric") {
        label = value.name.split("_").join(" ").toUpperCase();
      }

      let counter = 0;
      const activeValues = getActiveParamFromIdComponentUI(idAtribute)(state);
      if (Array.isArray(activeValues) && activeValues.length > 0) {
        activeValues.forEach((value) => {
          if (value === name) counter++;
        });
      }

      let enabled = value.enabled;
      if (idAtribute === "pillows") {
        // enabled = checkMaxCountPillows(state)(idAtribute);
        label = refactoringPillowsLabel(label);
      }

      if (idAtribute === "fabric" || idAtribute === "PillowMaterial") {
        // ВИДАЛИТИ ЦЕЙ КОД КОЛИ БУДУ МЕРЖИТИСЬ

        const configuratorType = location[1];
        const configs = ["sectional", "sofa", "bed"];

        let grades = ["Accent/Pillow", "Accents", "Leather"];

        if (configuratorType === "sofa" && (activeItemType?.includes("Chair") || activeItemType?.includes("Ottoman"))) {
          grades = grades.filter((i) => i !== "Accents");
        }
        const isBlockedConfig =
          configs.includes(configuratorType) &&
          idAtribute === "fabric" &&
          (effectiveAccountId === undefined || effectiveAccountId === "DEFAULT");
        const temporaryTest = isBlockedConfig ? value.metadata.grade && !grades.includes(value.metadata.grade) : true;
        //

        let hideCustomMaterial = true;

        if (configuratorType === "sofa" && idAtribute === "fabric") {
          const activeSofa = allThreekitAtributes["Sofa type"].values.find(
            (item: any) => item.assetId === allThreekitAtributes["Sofa type"].value.assetId
          );
          hideCustomMaterial = activeSofa.metadata.hideMaterial ? !grades.includes(value.metadata.grade) : true;
        }

        if (
          temporaryTest &&
          hideCustomMaterial &&
          value.tags &&
          value.tags.length >= 1 &&
          !value.tags.includes("deleted_materials")
        ) {
          values.push({
            name: name,
            label: label,
            visible: value.visible,
            metadata: value.metadata,
            tags: value.tags,
            enabled: enabled,
            count: counter,
          });
        }
      } else {
        values.push({
          name: name,
          label: label,
          visible: value.visible,
          metadata: value.metadata,
          tags: value.tags,
          enabled: enabled,
          count: counter,
        });
      }
    });

    if (idAtribute === "bed_size") {
      values = modelsBedroom.map((bed) => {
        const bedFromThreekit = values.find((value: any) => value.label === bed.label);
        if (bedFromThreekit) {
          return {
            ...bed,
            ...bedFromThreekit,
          };
        }
      });
    }
  }

  if (idAtribute === "fabric" || (idAtribute === "PillowMaterial" && values.length >= 1)) {
    console.log("materials_options_before", values);
    values = values
      .filter((i: any) => {
        if (restrictionsData) {
          const restrictionGradeKey = i.metadata.grade as keyof DealerCategoryAndFabricGradeRestrictions;
          const currentGradeAvailability = restrictionsData?.[restrictionGradeKey];
          return currentGradeAvailability;
        }

        return true;
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  //@ts-ignore
  window.valuess = values;
  return values;
};

export const getActiveParamFromIdComponentUI = (idComponentUI: string) => (state: RootState) => {
  const objActiveParamsUI = getObjActiveParamsUI(state);
  let paramValue = !!objActiveParamsUI[idComponentUI] ? objActiveParamsUI[idComponentUI] : null;
  if (typeof objActiveParamsUI[idComponentUI] === "boolean") paramValue = objActiveParamsUI[idComponentUI];

  return paramValue;
};

export const getOptionsArrForArmSelector = (idComponentUI: string) => (state: RootState) => {
  // const valueActiveUIArmSelectorWidth =
  //   getActiveParamFromIdComponentUI("ArmSelectorWidth")(state);
  const valuesOptionsFromThreekit = getValuesFromIdAttribute(idComponentUI)(state);
  // let optionsArrForArmSelector = [];
  // if (valueActiveUIArmSelectorWidth === "classic") {
  //   optionsArrForArmSelector = valuesOptionsFromThreekit.filter(
  //     (option: any) => !option.name.includes("Slope")
  //   );
  // } else {
  //   optionsArrForArmSelector = valuesOptionsFromThreekit.filter(
  //     (option: any) => option.name.includes("Slope")
  //   );
  // }
  // return optionsArrForArmSelector;
  return valuesOptionsFromThreekit;
};

export const getOptionsArrForArmSelectorByArmSelectorWidth =
  (state: RootState) => (idComponentUI: string, valueActiveUIArmSelectorWidth: string) => {
    // const valueActiveUIArmSelectorWidth = getActiveParamFromIdComponentUI("ArmSelectorWidth")(state);
    const valuesOptionsFromThreekit = getValuesFromIdAttribute(idComponentUI)(state);
    let optionsArrForArmSelector = [];
    if (valueActiveUIArmSelectorWidth === "classic") {
      optionsArrForArmSelector = valuesOptionsFromThreekit.filter((option: any) => !option.name.includes("Slope"));
    } else {
      optionsArrForArmSelector = valuesOptionsFromThreekit.filter((option: any) => option.name.includes("Slope"));
    }
    return optionsArrForArmSelector;
  };

export const getModelsGroupIdByCollectionId = (state: RootState) => (collectionId: collectionsKeysT) => {
  let modelsGroupId = "item_type";
  if (!!collectionId) {
    switch (collectionId) {
      case "sofa":
        modelsGroupId = "item_type";
        break;
      case "bed":
        modelsGroupId = "bed_size";
        break;
      case "sectional":
        modelsGroupId = "AdditionalItems";
        break;

      default:
        modelsGroupId = "item_type";
    }
  }
  return modelsGroupId;
};

export const getLoadPlayer = (state: RootState) => {
  return state.ui.loadConfig.loadPlayer;
};

export const getLoadInit3kit = (state: RootState) => {
  return state.ui.loadConfig.loadInit3kit;
};

export const getAllModals = (state: RootState) => {
  return state.ui.modals;
};

export const getModalInfo = (state: RootState) => (modalId: modalsName) => {
  const allModals = getAllModals(state);
  return allModals[modalId];
};

export const getNameImageForHeadboard = (state: RootState) => (parentId: string, optionTitle: string) => {
  const activeCollectionId = getActiveCollectionId(state);
  if (activeCollectionId !== "bed") return null;
  if (!["style", "height", "treatment"].includes(parentId)) return null;

  let valueShape = getActiveParamFromIdComponentUI("style")(state);
  let valueHeight = getActiveParamFromIdComponentUI("height")(state);
  let valueTreatment = getActiveParamFromIdComponentUI("treatment")(state);

  switch (parentId) {
    case "style":
      valueShape = optionTitle;
      break;

    case "height":
      valueHeight = optionTitle;
      break;

    case "treatment":
      valueTreatment = optionTitle;
      break;

    default:
      break;
  }

  const fileName = `${valueShape}__${valueHeight}__${valueTreatment}`;

  return fileName;
};

export const getObjActiveParamsUIFromObjActiveParamsThreekit =
  (state: RootState) => (paramsThreekit: modelSectionalT) => {
    const objActiveParamsUI = Object.keys(paramsThreekit).reduce(
      (accumulator: any, threekitAttributeName: string): objectActiveParamsT => {
        const paramsUI = getComponentIdUIFromNameThreekitAtribute(state)(threekitAttributeName);
        if (paramsUI === undefined) {
          return {
            ...accumulator,
            [threekitAttributeName]: paramsThreekit[threekitAttributeName],
          };
        }
        return {
          ...accumulator,
          [paramsUI]: paramsThreekit[threekitAttributeName],
        };
      },
      {}
    );
    return objActiveParamsUI;
  };

export const checkIsHidePrice = (state: RootState) => {
  const effectiveAccountId = state.ui.effectiveAccountId;
  const stateHide = state.ui.isHidePrice;

  const isHide =
    effectiveAccountId?.toUpperCase() === "DEFAULT" || effectiveAccountId === "Base account: no price"
      ? true
      : stateHide;

  return isHide;
};
export const getActivePillow = (state: RootState) => state.ui.activePillow;
export const getUserType = (state: RootState) => state.ui.userType;
export const getEffectiveAccountId = (state: RootState) => state.ui.effectiveAccountId;
export const getIsInStore = (state: RootState) => state.ui.inStore;
export const getModelsPrice = (state: RootState) => {
  const objInfoModelsPrice = getInfoModelsPrice(state);
  const objCountActiveModels = getCountActiveModels(state);

  const summPrice = Object.keys(objInfoModelsPrice).reduce((accumulator: number, modelKey: string) => {
    const modelCount = !!objCountActiveModels[modelKey] ? objCountActiveModels[modelKey] : 1;
    return (accumulator += objInfoModelsPrice[modelKey]["price"] * modelCount);
  }, 0);
  return summPrice;
};

export const getTotalPrice = (state: RootState) => {
  const priceModels = getModelsPrice(state);
  const pricePillows = getPillowsPrice(state);

  return priceModels + pricePillows;
};


export const getisLoadingPriceInfo = (state: RootState) => {
  return state.ui.loadConfig.loadPriceInfo
}
export const getCountAssets = (parentId: string, id: string) => (store: RootState) => {
  const threekitModels = getThreekitModels(store);
  const threekitPillows = getPillows(store);

  const getCountSofaSectional = (id: string) => {
    let counter = 0;
    threekitModels.map((model) => {
      if (id.includes(model["name"])) counter++;
    });
    return counter;
  };
  const getCountPillows = (id: string) => {
    let counter = 0;
    threekitPillows.map((model) => {
      if (id.includes(model["name"])) counter++;
    });
    return counter;
  };

  const getCount = (parentId: string, id: string) => {
    if (parentId === "AdditionalItems") return getCountSofaSectional(id);
    if (parentId === "pillows") return getCountPillows(id);
    return 0;
  };

  return getCount(parentId, id);
};

export const getAcualInfoFromModalById = (state: RootState) => (idModal: modalsName) => {
  const actualModal = state.ui.modals[idModal];
  return actualModal;
};
