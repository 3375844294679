import { modelT } from "../../redux/features/ui/ui.types";

export const modelsClassicSofa: modelT[] = [
  {
    img: "/images/assets/ClassicSofa/SofaPlus.png",
    label: "Sofa Plus",
    nameValueThreekit: "Sofa Plus",
    description: "Longer in length",
    subcategory: "Sofas",
    Clarification: () => {
      return <span>3 seat cushions</span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/Sofa.png",
    label: "Sofa",
    nameValueThreekit: "Sofa",
    description: "Classic sofa length",
    subcategory: "Sofas",
    Clarification: () => {
      return <span>2 seat cushions</span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/LoftSofa.png",
    label: "Loft Sofa",
    nameValueThreekit: "Loft Sofa",
    description: "A more compact sofa for smaller spaces",
    subcategory: "Sofas",
    Clarification: () => {
      return <span>2 seat cushions</span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/Loveseat.png",
    label: "Loveseat",
    nameValueThreekit: "Loveseat",
    description: "Most compact two-seater",
    subcategory: "Sofas",
    Clarification: () => {
      return <span>2 seat cushions</span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/ArmChair.png",
    label: "Arm Chair",
    nameValueThreekit: "Arm Chair",
    description: "Classic size chair",
    subcategory: "Accent Chairs",
    Clarification: () => {
      return <span></span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/CuddleChair.png",
    label: "Cuddle Chair",
    nameValueThreekit: "Cuddle Chair",
    description: "Extra wide chair",
    subcategory: "Accent Chairs",
    Clarification: () => {
      return <span></span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Ottoman",
    nameValueThreekit: "Ottoman",
    description: "Classic ottoman",
    subcategory: "Accent Ottomans",
    Clarification: () => {
      return <span></span>;
    },
  },
  {
    img: "/images/assets/ClassicSofa/CuddleOttoman.png",
    label: "Cuddle Ottoman",
    nameValueThreekit: "Cuddle Ottoman",
    description: "Wider and longer ottoman",
    subcategory: "Accent Ottomans",
    Clarification: () => {
      return <span></span>;
    },
  },
];
