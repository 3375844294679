import { useState } from "react";
//@ts-ignore
import { StandartOption } from "../StandartOption/StandartOption";
import s from "./PresetsOption.module.scss";

export const PresetsOption = ({ ...props }: any) => {
  let { id, values } = props;
  const [activePreset, setActivePreset] = useState();

  function otherOptional(info: any) {
    setActivePreset(info.value);
  }

  return (
    <div className={`${s.presetWrapper}`}>
      {values.map((option: any) => (
        <StandartOption
          id={!!option.value ? option.value : option.label}
          key={!!option.value ? option.value : option.label}
          parentId={id}
          {...option}
          value={option.label}
          activeValue={activePreset}
          otherOptional={(info: any) => otherOptional(info)}
          link={true}
        />
      ))}
    </div>
  );
};
