import { useEffect } from "react";
import MenuMain from "../../components/MainMenu/MenuMain/MenuMain";
import MenuSub from "../../components/MainMenu/MenuSub/MenuSub";
import s from "./MainPage.module.scss";
export default function MainPage() {
  useEffect(() => {
    window.sessionStorage.setItem("store", "{}");
    window.sessionStorage.setItem("itemType", "");

    if (window.player) {
      window.player.unload();
    }
  }, []);

  return (
    <div className={s.main}>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.logo}>
            <img src="/images/assets/MainLogo.png" alt="" />
          </div>
          <div className={s.title}>
            Furniture designed by you, for you is just a{" "}
            <span>
              {/* <img
                src="/images/assets/svg/TextUnderlineCurve.svg"
                alt=""
              /> */}
              few clicks
            </span>{" "}
            away. <span className={s.title_accent_secondary}>Mix it up. </span>
            <span className={s.title_accent}>Make it yours.</span>
          </div>
        </div>
        <div className={s.catalog__block}>
          <MenuMain />

          <MenuSub />
        </div>
      </div>
    </div>
  );
}
