import { modelT } from "../../redux/features/ui/ui.types";

export const modelsHeadboards: modelT[] = [
  {
    img: "/images/assets/CollectionImages/bed_size/38” modern.png",
    label: "38” modern",
    nameValueThreekit: "headboard_California King",
    subcategory: "Headboards",
  },
  {
    img: "/images/assets/CollectionImages/bed_size/50” classic.png",
    label: "50” classic",
    nameValueThreekit: "headboard_Eastern King",
    subcategory: "Headboards",
  },
  {
    img: "/images/assets/CollectionImages/bed_size/70” luxe.png",
    label: "70” luxe",
    nameValueThreekit: "headboard_Full",
    subcategory: "Headboards",
  },
];
