import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import useMobileWidth from "../../../hooks/useMobileWidth";
import { collectionUIT } from "../../../redux/features/ui/ui.types";
import { setActiveColection } from "../../../redux/features/ui/uiSlice";
import { getRestrictionByCategory, getSubMenuCollectionsArr } from "../../../redux/selectors/ui.selector";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";

import { filterArrayByRestrictions } from "../../../utils/restriction/restrictionUtils";
import s from "./MenuSub.module.scss";

let settingsSlider = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  onSwipe: (slide: string) => {},
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const MOB_ITEM_WIDTH = 220;

export default function MenuSub() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileSize = useMobileWidth();
  const elWrapper = useRef<HTMLDivElement>(null);
  const [elCatalogSubMobWidth, setElCatalogSubMobWidth] = useState({
    width: 0,
    marginSize: 0,
    marginDirection: "right",
  });
  const collectionsMenuSub = useAppSelector((state) => getSubMenuCollectionsArr(state));
  const categoryRestriction = useAppSelector(getRestrictionByCategory);

  const handleClick = (collection: collectionUIT) => {
    dispatch(setActiveColection(collection.id));
    navigate(collection.id, { state: { collectionId: collection.id } });
  };

  settingsSlider = {
    ...settingsSlider,
    // onSwipe: (directionSwipe: string) => {
    //   setElCatalogSubMobWidth({
    //     ...elCatalogSubMobWidth,
    //     marginDirection: directionSwipe,
    //   });
    // },
  };

  const filteredCollections = categoryRestriction
    ? filterArrayByRestrictions(collectionsMenuSub, categoryRestriction)
    : collectionsMenuSub;

  return (
    <div className={s.mobile_section} ref={elWrapper}>
      <div className={s.catalog_sub_background}></div>
      {!isMobileSize ? (
        <div className={s.catalog_sub}>
          {filteredCollections.map((collection: collectionUIT, index: number) => (
            <div className={s.catalog_item} key={`${collection.id}_${index}`} onClick={() => handleClick(collection)}>
              <img src={collection.img} alt={collection.label} />
              <span className={s.catalog_item_title}>{collection.label}</span>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={s.catalog_sub_mob}
          style={
            {
              // width: `${elCatalogSubMobWidth.width}px`,
              // margin: `0 ${
              //     elCatalogSubMobWidth.marginDirection === "right" ? elCatalogSubMobWidth.marginSize + 'px' : 0
              // } 0 ${
              //     elCatalogSubMobWidth.marginDirection === "left" ? elCatalogSubMobWidth.marginSize + 'px' : 0
              // }`,
            }
          }
        >
          <Slider {...settingsSlider}>
            {filteredCollections.map((collection: collectionUIT, index: number) => (
              <div className={s.catalog_item} key={`${collection.id}_${index}`} onClick={() => handleClick(collection)}>
                <img src={collection.img} alt={collection.label} />
                <span className={s.catalog_item_title}>{collection.label}</span>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
}
